<template>
    <div v-if="this.factoidCount">
        <h5>{{factoid.text}}</h5>
        <h3>
            <span v-for="number in calc" :key="number.i" :class="number.class" >{{number.value}}</span>
            {{factoid.unit}}
        </h3>
        <h6 v-if="factoid.reference">{{factoid.reference}}</h6>
    </div>

</template>


<script>
import {mapGetters} from 'vuex';
import Formatting from "@/util/formatting.js";
export default {
    computed : {
        ...mapGetters([
            'factoidCount',
            'factoid'
        ]),
        calc : function(){
            if(!this.factoidCount){
                return;
            }

            //let fm = (new Intl.NumberFormat('zh-Hant-CN-u-nu-hanidec', {
            //style: 'decimal',
            //useGrouping: true
            //}).format(_.ceil(this.factoidCount)));

            //return Formatting.htmlizeNumbers(fm);
            return Formatting.htmlizeNumbers(Formatting.numberToText(this.factoidCount));
            //return Formatting.htmlizeNumbers(this.factoidCount);
        }
    }
}

</script>